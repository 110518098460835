import React from "react"
import st from "../assets/styles/agreements.module.css"

const PrivacyPolicy = () => {
  return (
    <div className={st.agreements}>
      <div className={st.container}>
        <div className={st.docHeader}>
          УТВЕРЖДЕНО Приказом генерального директора Общества с ограниченной
          ответственностью «Джусисофт» от 20.08.2017 г. № 20
        </div>
        <h1>Политика конфиденциальности / Privacy policy</h1>
        <h2>мобильного приложения «Appbonus»</h2>
        <p>
          Настоящая Политика конфиденциальности (далее – Политика) мобильного
          приложения «Appbonus» (далее – Приложение) разработана и утверждена
          Обществом с ограниченной ответственностью «Джусисофт» (далее –
          Компания) в целях обеспечения режима информационной безопасности при
          использовании Приложения.
        </p>
        <p>
          Использование Приложения означает безоговорочное согласие Клиента с
          настоящей Политикой и указанными в ней условиями обработки информации,
          получаемой с Устройства Клиента.
        </p>
        <p>
          В случае несогласия с Политикой Клиент должен воздержаться от
          использования Приложения.
        </p>
        <p>
          Приложение и услуги в рамках Приложения реализуются Клиенту на
          основании договоров и соглашений с Компанией, которые в числе прочего
          регулируют все вопросы обработки и хранения Компанией персональных
          данных Клиента.
        </p>
        <p>
          Политика применима только к Приложению. Компания не контролирует и не
          несет ответственность за информацию (последствия её передачи),
          переданную Клиентом третьей стороне, в случае если такая передача была
          выполнена на ресурсе третьей стороны, на который Клиент мог перейти по
          ссылкам из Приложения.
        </p>
        <p>
          Компания имеет право вносить изменения в Политику путем размещения
          новой редакции Политики на сайте Компании и (или) в Приложении.
          Обязанность самостоятельного ознакомления с актуальной редакцией
          Политики лежит на Клиенте. Политика утверждена генеральным директором
          Компании и размещена на сайте Компании www.appbonus.ru.
        </p>
        <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
        <p>
          1. Термины, используемые в Политике, применяются в следующих
          значениях.
        </p>
        <p>
          1.1. Клиент – физическое лицо, являющийся пользователем Приложения и
          обратившийся к Компании с намерением получить или получившее
          финансовую услугу Компании с использованием Приложения.
        </p>
        <p>
          1.2. Компания – Общество с ограниченной ответственностью «Джусисофт»,
          являющееся поставщиком финансовой услуги Клиенту с использованием
          Приложения.
        </p>
        <p>
          1.3. Приложение – совокупность программ, баз данных для ЭВМ под
          наименованием «Мобильное приложение «Appbonus» (мобильная версия
          системы дистанционного обслуживания Компании) исключительное право, на
          которую принадлежит Компании в силу факта создания.
        </p>
        <p>
          1.4. Устройство – мобильное техническое устройство (смартфон, планшет
          или другое устройство), имеющее доступ к сети Интернет, на котором
          установлено Приложение.
        </p>
        <p>1.5. ОС – операционная система Устройства.</p>
        <p>
          1.6. ПО – программное средство, установленное на Устройстве и
          обеспечивающее функциональные свойства Приложения.
        </p>
        <h2>2. ИНФОРМАЦИЯ ПОЛЬЗОВАТЕЛЯ</h2>
        <p>
          Состав информации, которая может быть получена с Устройства при
          использовании Приложения и цели ее получения:
        </p>
        <p>
          2.1. Информация о местоположении Устройства (на основе данных сети
          оператора сотовой связи и сигналов GPS) может быть получена Компанией
          с целью информирования Клиента при использовании Приложения о
          местоположении подразделений Компании, ее представительств и (или)
          третьих лиц, привлеченных Компанией на основе агентирования в целях
          предоставления финансовых услуг, а также о дополнительных сервисах,
          доступных Клиенту и обусловленных его местоположением.
        </p>
        <p>
          2.2. Фотоизображения, полученные с использованием камеры Устройства с
          целью получения и использования фотоизображений в рамках услуг,
          реализуемых в Приложении, в том числе для создания и сохранения
          фотоизображений в профиле Клиента в Приложении.
        </p>
        <p>
          2.3. Информация о версии операционной системы и модели Устройства,
          получаемая Компанией с целью анализа возможных ошибок в работе
          Приложения и совершенствование работы Приложения. Для целей анализа
          Компания может передавать информацию об операционной системе и модели
          Устройства сторонним организациям в обезличенном виде. 2.4. Информация
          об IP-адресе и адресе точки подключения Клиента, получаемая Компанией
          с целью повышения безопасности Клиента при использовании Приложения.
        </p>
        <p>
          2.5. Информация об СМС-сообщениях на Устройстве может быть получена
          Компанией с целью сохранения и использования в Приложении
          СМС-сообщений, поступивших от Компании (с идентификатором отправителя
          Appbonus).
        </p>
        <p>
          2.6. Аудиоданные, полученные с использованием микрофона Устройства с
          целью выполнения аудио звонков Клиента в Компанию с использованием
          Приложения.
        </p>
        <p>
          2.7. С целью улучшения качества работы ПО и обновления ПО: информация
          о результатах обновления ПО, данные об установленной ОС, техническая и
          иная информация, автоматически передаваемая устройством, информация о
          возникших ошибках в работе компонент ПО.
        </p>
        <h2>3. УСЛОВИЯ ОБРАБОТКИ ИНФОРМАЦИИ</h2>
        <p>
          3.1. В соответствии с настоящей Политикой Компания осуществляет
          обработку только той информации и только для тех целей, которые
          определены в разделе 2 Политики.
        </p>
        <p>
          3.2. Компания принимает все зависящие от нее организационные и
          технические меры для защиты информации Клиента от неправомерного
          доступа третьих лиц, использования, копирования и распространения.
        </p>
        <p>
          3.3. Для целей, изложенных в Политике, Компания может привлекать к
          обработке информации Клиента партнеров, с которыми у Компании
          заключены соответствующие соглашения о конфиденциальности. Передача
          Компанией партнерам обезличенных данных об использовании Приложения
          для целей улучшения работы Приложения осуществляется на основании
          договоров с партнерами.
        </p>
        <p>
          3.4. Информация Клиента может сохраняться на ресурсах Компании и его
          партнеров в течение срока действия договорных отношений между
          Компанией и Клиентом касаемо Приложения, а также в течение пяти лет
          после расторжения таких договоров.
        </p>
        <p>
          3.5. Информация Клиента может быть предоставлена государственным
          органам в соответствии с требованиями действующего законодательства
          Российской Федерации.
        </p>
        <h2>4. ПОРЯДОК УТВЕРЖДЕНИЯ И ИЗМЕНЕНИЯ ПОЛИТИКИ</h2>
        <p>
          4.1. Компания вправе вносить не противоречащие требованиям
          законодательства Российской Федерации изменения и дополнения в
          Политику.
        </p>
        <p>
          4.2. Безусловным основанием для внесения изменений и дополнений в
          Политику является существенное изменение законодательства Российской
          Федерации в области микрофинансовой деятельности.
        </p>
        <p>
          4.3. Изменения и дополнения, вносимые в Политику, вводятся в действие
          после их утверждения Компанией.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
